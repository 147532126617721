import React from "react"
import PrivacyPolicyTemplate from "components/PrivacyPolicyTemplate/PrivacyPolicyTemplate"


const OptOut = ({ data }) => {
    const row = {
        title: "SDK Opt-Out - Adinify Inc.",
        contents: []
    }

    return (
        <PrivacyPolicyTemplate row = { row } data = { data }/>
    )
}

export default OptOut