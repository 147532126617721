import React from 'react';
import Grid from '@mui/material/Grid2';
import GeneralTitle from 'components/GeneralTitle/GeneralTitle';
import IconCard from './IconCard';
import './iconCardColumn.css'

const IconCardColumn = ({rowData={},imageWidth})=> {
    return (
        <Grid container py={2}>
            <Grid size={12}>
            <GeneralTitle rowData={rowData}/>
            </Grid>
            {
                rowData?.cards &&
                rowData?.cards.map((card,i)=>(
                    <Grid size={{md:4,xs:12}} key={i} className='center-align'>
                        <IconCard card={card} imageWidth={imageWidth}/>
                    </Grid>
                ))
            }
        </Grid>
    );
}

export default IconCardColumn;