const optOutData = [
    {
        title: 'SDK Opt-Out',
        texts: [
            `Adinify Inc., an advertising mediation service, facilitates the connection between advertisers and mobile app publishers to deliver advertisements to audiences potentially interested in their products or services. The advertisement you are viewing was delivered to enhance your online experience by customizing it based on information collected by Adinify Inc. regarding you, your device, and the advertisements you view or interact with.`,
            `For further details on the data collected by Adinify Inc. and its utilization, please refer to Adinify Inc.’s Privacy Policy.`,
            `Adinify Inc. acknowledges the importance of user privacy and provides an option for users to opt out of receiving interest-based advertising on devices supported by Adinify Inc. To exercise this opt-out right, please adhere to the following instructions:`,
            `Device Opt-Out: To opt out on your device, locate the “Limit Ad Tracking” option on your iOS mobile device under Settings/Privacy/Advertising, or the “Opt out of Ad Personalization” under Settings/Google/Ads on your Android device. Upon activating the “opt-out” setting, Adinify Inc. will refrain from using in-app information collected from that device to deduce your interests or serve ads to that device that are targeted based on your inferred interests.`,
            `Please be advised that opting out restricts Adinify Inc.’s collection of information for the purpose of serving ads based on your inferred interests; however, it does not preclude you from receiving non-targeted advertisements from Adinify Inc. or third parties.`,
            `Additionally, due to the various methods employed by Adinify Inc. to collect user data, it may require up to 48 hours to fully process your opt-out request.`
        ]
    },
]

export default optOutData