import React from 'react';
import Grid from '@mui/material/Grid2'
import { Button, Link } from '@mui/material';

const HomeHeader=({rowData})=> {
    return (
        <Grid className='background-video-container' sx={{
            minHeight: { xs: '170vh', sm: '100vh' },
        }}>
            <video autoPlay muted loop className="background-video">
                <source src="https://cdn.pixfuture.com/video/853870-hd_1920_1080_25fps.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="overlay"></div>
            <Grid className='content' sx={{
                marginTop: { sm: '5%' },
            }}>
                <h1 style={{ fontSize: '60px' }} >{rowData?.title}</h1>
                <p>Connect directly with adinify's global advertisers and agencies, powered by an advanced machine learning platform</p>
                <Grid container
                justifyContent={'center'}
                spacing={2}
                marginTop='20px'
                sx={{
                    flexDirection: { xs: 'column', sm: 'row' }
                }}
                >
                    <Grid item container
                        sx={{
                            backgroundColor: 'rgba(0, 59, 162, 0.5)',
                            minHeight: '150px',
                            maxWidth: { xs: '100%', sm: '45%' },
                            textAlign: 'left',
                            padding: '20px',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            }}
                    >
                        <Grid container spacing={0.5}>
                            <Grid backgroundColor='rgb(0, 135, 198)' width='auto' borderRadius='15px' marginBottom='10px' padding="5px 15px" display='inline-block'>
                                <h4 style={{ fontWeight: 'bold', fontSize: '23px' }} >
                                App developers
                                </h4>
                            </Grid>
                            <h5 style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                            A dependable platform to increase and optimize your mobile advertising revenue
                            </h5>
                            <h6 style={{ fontSize: '15px' }}>
                            Gain complete control, flexibility, and transparency in your advertising business. Now is the time to build and grow your successful venture.
                            </h6>
                        </Grid>
                        <Link
                            href='#get-started' rel="noreferrer"><Button sx={{
                            color:'#fff',
                            width:110,
                            height:50,
                            textTransform: 'none',
                            borderRadius: '15px',
                            backgroundColor: '#242424',
                            transition: 'box-shadow 0.4s ease-in-out, background-color 0.5s ease-in-out',
                            '&:hover': {
                            boxShadow: '0 0 5px 6px rgba(126, 126, 126, 0.1)',
                            backgroundColor: '#363636',
                            display: 'inline-block'
                            },
                            }}>
                            Learn more
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item container
                        sx={{
                            backgroundColor: 'rgba(0, 59, 162, 0.5)',
                            minHeight: '150px',
                            maxWidth: { xs: '100%', sm: '45%' },
                            textAlign: 'left',
                            padding: '20px',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                          }}
                    >
                        <Grid container spacing={0.5}>
                            <Grid backgroundColor='rgb(0, 135, 198)' width='auto' borderRadius='15px' marginBottom='10px' padding="5px 15px" display='inline-block'>
                                <h4 style={{ fontWeight: 'bold', fontSize: '23px' }} >
                                Demands and agencies
                                </h4>
                            </Grid>
                            <h5 style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                            Reach worldwide in-app audiences and grow your global presence.
                            </h5>
                            <h6 style={{ fontSize: '15px' }} >
                            Access high-quality, direct, and brand-safe in-app inventory for all ad formats, optimized for the best performance.
                            </h6>
                        </Grid>
                        <Link
                            href='#get-started' rel="noreferrer"><Button sx={{
                            color:'#fff',
                            width:110,
                            height:50,
                            textTransform: 'none',
                            borderRadius: '15px',
                            backgroundColor: '#242424',
                            transition: 'box-shadow 0.4s ease-in-out, background-color 0.5s ease-in-out',
                            '&:hover': {
                            boxShadow: '0 0 5px 6px rgba(126, 126, 126, 0.1)',
                            backgroundColor: '#363636'
                            },
                            }}>
                            Learn more
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default HomeHeader;