import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from 'views/Home/Home';
import Layout from 'views/Base/Layout';
import PartnersPrivacy from './views/PartnersPrivacy/PartnersPrivacy';
import partnersPrivacyData from 'data/PartnersPolicy/partnersPolicyData';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy';
import privacyPolicyData from 'data/PrivacyPolicy/privacyPolicyData';
import optOutData from 'data/OptOut/optOutData';
import OptOut from './views/OptOut/OptOut';
import termsOfServiceData from 'data/TermsOfService/termsOfServiceData';
import TermsOfService from './views/TermsOfService/TermsOfService';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home />}/>
          <Route path='/partners-privacy' element={ <PartnersPrivacy data = { partnersPrivacyData } />} />
          <Route path='/privacy-policy' element={ <PrivacyPolicy data = { privacyPolicyData } /> } />
          <Route path='/opt-out' element={ <OptOut data = { optOutData } /> } />
          <Route path='/terms-of-service' element={ <TermsOfService data = {termsOfServiceData} /> } />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

