import React from "react"
import Grid from '@mui/material/Grid2'
import GeneralTitle from "components/GeneralTitle/GeneralTitle"


const PrivacyPolicyTemplate = ({ row, data }) => {

    return (
        <Grid container className='basic-page-container center-align'>
            <GeneralTitle rowData={row}/>
            <Grid
                container
                marginTop='60px'
                direction='column'
                size={{ md: 9, xs: 12 }}
                sx={{
                    background: 'rgba(255,255,255,0.1)',
                    borderRadius: "15px"
                }} 
                className='center-align'
                alignItems='flex-start'
                spacing={2}
            >
                {data.map((item, index) => (
                    <Grid container direction='column' marginBottom='20px' key={index}>
                        <h2>{item.title}</h2>
                        { item.texts.map((item, index) => (
                            <Grid key={index}>
                                <p>{ item }</p>
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default PrivacyPolicyTemplate