import React from "react";
import PrivacyPolicyTemplate from "../../components/PrivacyPolicyTemplate/PrivacyPolicyTemplate"

const TermsOfService = ({ data }) => {
    const row = {
        title: "Terms of Service",
        contents: []
    }

    return (
        <PrivacyPolicyTemplate row = { row } data = { data }/>
    )
}

export default TermsOfService