import React from 'react';
import { Slide } from '@mui/material';
import { WideContainer } from 'components/Base/MUI/containers/PxftContainer';
import { useInView } from 'react-intersection-observer'
import Grid from '@mui/material/Grid2'
import './generalTitile.css'

const GeneralTitle = ({containerStyle={},rowData={}}) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
        <WideContainer>
            <Grid className='center-align basic-title' px={2} mt={3}>
                <Grid container className={`${containerStyle}`} ref={containerRef}> 
                    <Slide timeout={{ enter: 1200 }} in={inView} direction="down" container={containerRef.current}>
                        <Grid size={{xs:12}} className='center-align'>
                            <h1>{rowData?.title}</h1>
                        </Grid>
                    </Slide>
                    <Slide timeout={{ enter: 1200 }} in={inView} direction="up" container={containerRef.current}>
                        <Grid size={{xs:12}} className='center-align' flexDirection='column' pt={2}>
                            {
                                rowData?.contents && 
                                    rowData?.contents.map((content, i)=>(
                                        <p key={i}>{content}</p>
                                    ))
                            }
                        </Grid>
                    </Slide>
                    <div className='observer' ref={inViewRef}></div>
                </Grid>
            </Grid>
        </WideContainer>
    );
}

export default GeneralTitle;