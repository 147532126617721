import React from 'react';
import Grid from '@mui/material/Grid2';


const IconCard = ({card,imageWidth='30px'}) => {
    return (
        <Grid container maxWidth='300px' py={4} className='icon-card center-align'>
            <Grid size={12} className='center-align' py={2}>
                <img loading='lazy' className='icon-card-img' src={card.imgSrc} width={imageWidth} alt=''/>
            </Grid>
            <Grid>
                <h1>{card.title}</h1>
            </Grid>
            <Grid>
                <p>{card.content}</p>
            </Grid>
        </Grid>
    );
}

export default IconCard;