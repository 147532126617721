const partnersPrivacyData = [
    {
        title: "1.Purpose",
        texts: [
            `Adinify Inc., a corporation duly incorporated under the laws of Canada, hereby establishes this Privacy Policy (“Policy”) to affirm our dedication to safeguarding the privacy of our users. The terms “Adinify,” “we,” “us,” and “our” shall be construed to include Adinify Inc. and its affiliates and subsidiaries. The terms “you” and “your” shall refer to any individuals and entities utilizing our Services or accessing our Website, including but not limited to publishers, advertisers, demand partners, and other service providers, as well as our Employees.`,
            `This Policy delineates our practices concerning the collection, use, and dissemination of information, both online and offline, the categories of information we may collect, the purposes for which such information may be utilized, the parties with whom such information may be shared, and the mechanisms by which you may opt-out of such uses or amend such information. By availing yourself of our Services or accessing our Website, you hereby consent to the practices articulated in this Policy. This Policy constitutes an integral component of, and is hereby incorporated by reference into, our Terms of Service (the “Terms”). Any capitalized terms not expressly defined herein shall bear the meanings ascribed to them in the Terms.`,
        ]
    },
    {
        title: "2.	Scope",
        texts: [
            `This Policy shall govern any information pertaining to an identified or identifiable natural person (“Personal Information”) that is collected, recorded, stored, adapted, utilized, or otherwise made available (collectively, “Processed”) by Adinify Inc. in the course of conducting our business, which includes providing an ad mediation platform, tools, and other services (the “Services”) or the functionalities of the Website. All individuals and entities tasked with the responsibility of Processing Personal Information on our behalf are mandated to safeguard such data in strict compliance with this Policy. This Policy is designed to fulfill requirements on a global scale, encompassing jurisdictions in North America, Europe, APAC, and other regions.`
        ],
    },
    {
        title: "3.	Transparency/Notice: Types of Personal Information We Collect and How We Use It",
        texts: [
            `The categories of Personal Information that we may collect (whether directly from you or from Third-Party sources) and our privacy practices are contingent upon the nature of your relationship with Adinify Inc. and the stipulations of applicable law. Adinify Inc. may collect Personal Information through the following means: You may voluntarily provide Personal Information directly to Adinify Inc. through interactions with the Services or our Website, participation in surveys, attendance at events, and requests for products, Services, or information.`,
            `During your interaction with the Services or our Website, certain passive information may also be collected regarding your visit, including through Technologies as delineated below. We strive to collect solely the information pertinent to the purposes of Processing. Enumerated below are the methods by which we collect Personal Information and the purposes for which we utilize it.`,
            `(a) Types of Personal Information Collected by Adinify Inc. Adinify Inc. collects Personal Information pertaining to its current, prospective, and former clients, customers, publishers, demand partners, users, visitors, guests, and Employees (collectively referred to as “Individuals”).`,
            `Information Provided Directly to Us: In the course of utilizing the Services or the Website, or engaging in specific activities such as account registration with Adinify Inc., requesting Services or information, or direct communication with us, you may be requested to furnish some or all of the following categories of information:`,
            `Account Registration: Upon registering for an account to access our Services, we collect information about you, which may encompass your name, address, telephone number, email address, and billing information, including your credit card number.`,
            `Communications with Us: We may also collect Personal Information from you, such as your name, email address, phone number, or mailing address, when you elect to request information about our products and Services, subscribe to a newsletter, contact customer service, or otherwise engage in communication with us.`,
            `Surveys: Periodically, we may invite you to participate in online surveys. Should you choose to participate, you may be requested to provide certain information, which may include Personal Information. All information collected from your participation in our surveys is provided voluntarily. We may utilize such information to enhance our products, Website, and/or Services, and in any manner consistent with the policies articulated herein. Automatic Data Collection: We may automatically collect certain information through our Services, Website, or other web analysis methods, such as your Internet Protocol (IP) address, cookie identifiers, mobile advertising identifiers, and other device identifiers automatically assigned to your Device upon Internet access, location data, browser type, operating system, Internet service provider, pages visited before and after using the Website or our Services, the date and time of your visit, the duration of time spent on each page, information regarding links clicked and pages viewed on our Website, and other actions undertaken through the use of the Website or Services, such as preferences. Information from Other Sources: We may also acquire information about you through Third-Party services and organizations to supplement the information provided by you. This supplemental information enables us to verify the information you have provided to Adinify Inc. and to enhance our capacity to furnish you with information regarding our business, products, and Services.`,
            `(b) Cookies, Pixel Tags/Web Beacons, Analytics Information, and Interest-Based Advertising`,
            `Adinify Inc., along with third parties providing content, advertising, or other functionalities on our Services or Website, may employ cookies, pixel tags, local storage, mobile device identifiers, and other technologies (“Technologies”) to automatically gather information through the Services or Website. These Technologies, which are essentially small data files placed on your computer, tablet, mobile phone, or other devices (collectively referred to as a “Device”), enable us to record specific pieces of information whenever you visit or interact with our Services and to recognize you across Devices.`,
            `Cookies: Cookies are data fragments stored on your Device via your browser or mobile application, allowing the application to remember you upon subsequent visits. Cookies facilitate a smoother, more efficient use of the Website by storing your specific information and preferences, such as theme, language setting, privacy preferences, and even user ID and password, thereby eliminating the need to reenter these details each time you visit the Website. You may opt to block or delete cookies if your browser permits; however, doing so may impact your use of the Website and your ability to access certain features. Advertising Identifiers: We may also utilize mobile advertising identifiers to recognize your device and perform services. Depending on your device, you may choose to modify or limit the transmission of your mobile advertising identifier through your device settings. Web Bugs/Pixel Tags/Web Beacons: We may employ web bugs (also known as web beacons) to gain insights into user behavior by tracking user demographics and traffic patterns.`,
            `Social Media Widgets: Our Website incorporates social media features, such as the Facebook “Like” button and LinkedIn widgets (which may include the share button or other interactive mini-programs). These features may collect information, including your IP address and the page you are visiting on our Website, and may set a cookie to enable the feature to function properly. These social media features are either hosted by a third party or directly on our Website. Your interactions with these features are governed by the privacy policy of the company providing them.`,
            `Analytics: We may utilize Google Analytics and Yandex Metrics to collect, compile, and analyze information regarding visitor behavior and demographics on some of our Services, and to develop website content. This analytics data is not linked to any Personal Information. For more information about Google Analytics, please visit policies.google.com/technologies/partner-sites. You can opt out of Google’s data collection and Processing by visiting tools.google.com/dlpage/gaoptout. You can opt out of Yandex Metrics by visiting yandex.com/support/metrica/general/opt-out.html.`,
            `Our utilization of such Technologies falls into the following general categories: Operationally Necessary: We may use Technologies essential to the operation of our Services or Website. This includes Technologies that grant you access to our Services or are required to identify irregular Website behavior, prevent fraudulent activity, and enhance security.`,
            `Performance Related: We may use Technologies to evaluate the performance of our Services or Website, including as part of our analytics practices, to understand how visitors use our Website, determine if you have interacted with our messaging, ascertain whether you have viewed an item or link, or to improve our Services or Website. Functionality Related: We may use Technologies that enable us to offer enhanced functionality when accessing or using our Services or Website. This may include identifying you when you sign into our Website or tracking your specified preferences, interests, or past items viewed to enhance the presentation of content on our Website. Advertising or Targeting Related: We may use first-party or Third-Party Technologies to deliver content, including ads relevant to your interests, on our Website or Third-Party websites. This includes using Technologies to understand the relevance of advertisements and content delivered to you, such as whether you have clicked on an advertisement. We may also combine the information collected through Technologies with other Personal Information we have gathered from and about you. If you wish to opt out of the Technologies we employ through our Services, you may do so by blocking, deleting, or disabling them as permitted by your browser or Device.`,
            `(c) Utilization of Your Information by Adinify Inc.`,
            `Adinify Inc. acquires, retains, utilizes, and Processes Personal Information based on legal grounds and for legitimate business purposes as delineated in this Policy, including but not limited to:`,
            `Provision of Services or Information Requested: Adinify Inc. may utilize information about you to fulfill requests for Services or information, including details about potential or future Services, such as to:`,
            `•	Manage Individual information and accounts generally;`,
            `•	Respond to inquiries, comments, and other requests;`,
            `•	Provide access to specific areas, functionalities, and features of Adinify Inc.’s Services or Website;`,
            `•	Facilitate event registration.`,
            `Administrative Purposes: Adinify Inc. may utilize Personal Information about you for administrative purposes, including to:`,
            `•	Ensure a personalized and consistent experience in using the Website and Services;`,
            `•	Safeguard the security of our Service and Website and prevent fraud;`,
            `•	Measure interest in Adinify Inc.’s Services and perform analytics;`,
            `•	Notify you of changes to the Website or Services;`,
            `•	Develop new Services;`,
            `•	Ensure internal quality control;`,
            `•	Verify Individual identity;`,
            `•	Communicate regarding Individual accounts and activities on Adinify Inc.’s Services, Website, and systems, and, at Adinify Inc.’s discretion, changes to any Adinify Inc. policy;`,
            `•	Send emails to the email address you provide to verify your account and for informational and operational purposes, such as account management, customer service, or system maintenance;`,
            `•	Process payment for Services purchased;`,
            `•	Process applications and transactions;`,
            `•	Prevent potentially prohibited or illegal activities;`,
            `•	Enforce our Terms of Use.`,
            `Marketing Adinify Inc. Services: Adinify Inc. may utilize Personal Information to provide you with materials about offers, products, and Services that may be of interest, including new content or Services. Adinify Inc. may provide these materials via phone, postal mail, facsimile, or email, as permitted by applicable law. Such uses include:`,
            `•	Tailoring content, advertisements, and offers;`,
            `•	Notifying you about offers, products, and services that may interest you;`,
            `•	Providing Services to you and our sponsors;`,
            `•	Other purposes disclosed at the time Individuals provide Personal Information;`,
            `•	Otherwise with your consent.`,
            `You may contact us at any time to opt-out of the use of your Personal Information for marketing purposes, as further described in Section 6 below.`,
            `Research and Development: Adinify Inc. may utilize Personal Information to create non-identifiable information that we may use alone or in aggregate with information obtained from other sources, to improve our Website, optimally deliver existing Services, or develop new Services. From time to time, Adinify Inc. may conduct research (online and offline) via surveys. We may engage Third-Party service providers to conduct such surveys on our behalf. All survey responses are voluntary, and the information collected will be used for research and reporting purposes to better serve Individuals by understanding their needs and the quality of the Services we provide. Survey responses may be used to assess the effectiveness of our Services or Website, various communications, advertising campaigns, and/or promotional activities. If an Individual participates in a survey, the information provided will be used alongside that of other study participants. We may share anonymous Individual and aggregate data for research and analysis purposes.`,
            `Direct Mail, Email, and Outbound Telemarketing: Individuals who provide us with Personal Information, or whose Personal Information we obtain from Third Parties, may receive periodic emails, newsletters, mailings, or phone calls from us with information on Adinify Inc.’s or our business partners’ products and services or upcoming special offers/events we believe may be of interest. We offer the option to decline these communications at no cost to the Individual by following the instructions in Section 6 below.`,
            `Mobile Devices: Adinify Inc. provides Services specifically designed for compatibility and use on mobile Devices. Adinify Inc. will collect certain information that your mobile Device sends when you use such Services, like GPS location, user settings, and the operating system of your Device. Adinify Inc.’s Services require users to log in with an account, and information collected through mobile versions of the Services is associated with user accounts. Additionally, Adinify Inc. may enable Individuals to download an application, widget, or other tool for use on Devices. Some of these tools may store information on Devices. These tools may transmit Personal Information to Adinify Inc. to enable Individuals to access user accounts and allow Adinify Inc. to track tool usage. Some tools may enable users to email reports and other information from the tool. Adinify Inc. may use personal or non-identifiable information transmitted to enhance these tools, develop new tools, improve quality, and as otherwise described in this Policy or other notices provided by Adinify Inc.`,
            `Anonymous and Aggregated Information: Adinify Inc. may utilize Personal Information and other information about you to create anonymized and aggregated information, such as de-identified demographic information, de-identified location information, information about the Device from which you access Adinify Inc.’s Services or Website, or other analyses we create. Anonymized and aggregated information is used for various functions, including measuring visitors’ interest in and use of various portions or features of the Services or Website. Anonymized or aggregated information is not Personal Information, and Adinify Inc. may use such information in numerous ways, including research, internal analysis, analytics, and any other legally permissible purposes. We may share this information within Adinify Inc. and with Third Parties for our or their purposes in an anonymized or aggregated form designed to prevent anyone from identifying you.`,
            `Sharing Content with Friends or Colleagues: Adinify Inc.’s Services may offer various tools and functionalities. For example, Adinify Inc. may provide functionality on its Services that allows you to forward or share certain content with a friend or colleague. Email addresses you provide for a friend or colleague will be used to send your friend or colleague the content or link you request but will not be collected or otherwise used by Adinify Inc. or any other Third Parties for any other purpose.`,
            `Other Uses: Adinify Inc. may utilize Personal Information for other purposes for which we have a legitimate interest, such as direct marketing, individual or market research, anti-fraud protection, or any other purpose disclosed to you at the time you provide Personal Information or with your consent.`,
            `(d) Third-Party Websites and Social Media Platforms`,
            `The Website may include hyperlinks to external websites, and conversely, external websites may reference or hyperlink to our Website or other Services. These external domains and websites are beyond our control, and Adinify Inc. neither endorses nor makes any representations concerning Third-Party websites or social media platforms. We advise our users to examine the privacy policies of each website and application they engage with. We do not endorse, screen, or approve, and bear no responsibility for, the privacy practices or content of such other websites or applications. Access to these other websites or applications is undertaken at your own risk.`,
            `The Website and our Services may also incorporate links and interactive features with various social media platforms. If you are already a user of these platforms, their cookies may be set on your Device when utilizing our Website or Services. You should be cognizant that Personal Information you voluntarily include and transmit online in a publicly accessible blog, chat room, social media platform, or otherwise online, or that you share in an open forum, may be observed and utilized by others without restrictions. We are unable to regulate such uses of your information when interacting with a social media platform, and by employing such services, you assume the risk that the Personal Information provided by you may be observed and utilized by third parties for diverse purposes.`,
            `(e) Software Development Kit`,
            `To deliver our Services, we provide a software development kit (“SDK”) that enables us to access your Mobile Properties. Through our SDK, we may collect Personal Information about users of your Mobile Properties, including IP addresses, mobile advertising IDs, GPS location (if appropriate permission is granted), application usage statistics such as launch times and duration, ad views, and click-throughs. Advertisers or demand partners delivering content through our SDK may also collect Personal Information or other information about your users via our SDK. You agree that, to utilize our SDK, you will secure appropriate consent from your users for the collection, use, and sharing of Personal Information through our SDK, and that you will maintain and process all user data in accordance with your privacy policy and all applicable laws in any jurisdiction where your Mobile Properties are distributed or used and users are located.`,
            `If you employ our SDK as a publisher, you agree that we may also share certain non-personal information about your use of the SDK through your Mobile Properties with advertisers and demand partners who provide content through our SDK. Such information may encompass the number of requests you receive for ad placements on your Mobile Properties, the number of impressions and click-throughs generated, information about revenue you receive, and eCPM data.`,
        ]
    },
    {
        title: `4.	Human Resources Data`,
        texts: [
            `Adinify Inc. collects Personal Information from Employees, their designated contacts in the event of a medical emergency, and beneficiaries under any insurance policy (“Human Resources Data”). “Employees” encompass current, temporary, permanent, prospective, or former employees, directors, contractors, workers, or retirees of Adinify Inc. The Human Resources Data we collect may include title, name, address, phone number, email address, date of birth, passport number, driver’s license number, Social Security number or other government-issued identification number, financial information related to credit checks, bank details for payroll, information that may be recorded on a CV or application form, language abilities, contact information of third parties in case of an emergency, and beneficiaries under any insurance policy. We may also collect sensitive Human Resources Data such as details of health and disability, including mental health, medical leave, and maternity leave, and information about national origin or immigration status.`,
            `We acquire, hold, use, and Process Human Resources-related Personal Information for various business purposes, including:`,
            `•	Workflow management, including assigning, managing, and administering projects;`,
            `•	Human Resources administration and communication;`,
            `•	Payroll and the provision of benefits;`,
            `•	Compensation, including bonuses and long-term incentive administration, stock plan administration, compensation analysis, including monitoring overtime and compliance with labor laws, and company recognition programs;`,
            `•	Job grading activities;`,
            `•	Performance and employee development management;`,
            `•	Organizational development and succession planning;`,
            `•	Benefits and personnel administration;`,
            `•	Absence management;`,
            `•	Helpdesk and IT support services;`,
            `•	Regulatory compliance;`,
            `•	Internal and/or external or governmental compliance investigations;`,
            `•	Internal or external audits;`,
            `•	Litigation evaluation, prosecution, and defense;`,
            `•	Diversity and inclusion initiatives;`,
            `•	Restructuring and relocation;`,
            `•	Emergency contacts and services;`,
            `•	Employee safety;`,
            `•	Compliance with statutory requirements;`,
            `•	Processing of Employee expenses and travel charges;`,
            `•	Acquisitions, divestitures, and integrations.`
        ]
    },
    {
        title: `5.	Transfer—Adinify Inc. May Disclose Your Information`,
        texts: [
            `(a) Information We Share`,
            `Adinify Inc. may disclose your information as delineated in this Policy (e.g., to our Third-Party service providers; to comply with legal obligations; to protect and defend our rights and property) or with your explicit consent. A comprehensive list of Third-Party service providers can be accessed here: adinify.com/partners-privacy`,
            `We Utilize Vendors and Service Providers: Adinify Inc. may disclose any information we receive to vendors and service providers, including advertisers that deliver content through our SDK. The categories of service providers (processors) to whom we entrust Personal Information encompass service providers for: (i) the provision of IT and related services, such as web hosting or data management; (ii) the provision of information and services you have requested; (iii) payment processing; (iv) customer service activities; and (v) in connection with the provision of the Website. Adinify Inc. has executed appropriate contractual agreements with these service providers, prohibiting them from using or disclosing Personal Information except as necessary to perform the contracted services on our behalf or to comply with applicable legal requirements.`,
            `Business Partners: Adinify Inc. may elect to share Personal Information with our business partners and affiliates for our and our affiliates’ internal business purposes, or to furnish you with Services that you have requested. Adinify Inc. may also provide Personal Information to business partners with whom we may jointly offer products or services, or whose products or services we believe may be of interest to you. In such instances, the name of our business partner will appear alongside Adinify Inc.’s. Adinify Inc. mandates that our affiliates and business partners agree in writing to uphold the confidentiality and security of Personal Information they maintain on our behalf and to refrain from using it for any purpose other than the purpose for which Adinify Inc. provided it to them.`,
            `Interest-Based Advertising and Third-Party Marketing: Through our Services, Adinify Inc. may permit Third-Party advertising partners to establish tracking tools (e.g., cookies or other Technologies) to collect information regarding your activities (e.g., your IP address, page(s) visited, time of day). We may also share such de-identified information as well as selected Personal Information (such as demographic information and past purchase history) we have collected with Third-Party advertising partners. These advertising partners may utilize this information (and similar information collected from other websites) for the purpose of delivering targeted advertisements to you when you visit non-Adinify Inc. related websites within their networks. This practice is commonly referred to as “interest-based advertising” or “online behavioral advertising.” We may permit access to other data collected by the Website to facilitate the transmission of information that may be useful, relevant, valuable, or otherwise of interest to you. Should you prefer that we do not share your Personal Information with Third-Party advertising partners, you may opt-out of such sharing at no cost by following the instructions in Section 6 below.`,
            `Disclosures to Protect Us or Others (e.g., As Required by Law and Similar Disclosures): Adinify Inc. may access, preserve, and disclose your Personal Information, other account information, and content if we believe such action is required or appropriate to: (i) comply with law enforcement or national security requests and legal processes, such as a court order or subpoena; (ii) respond to your requests; (iii) protect your, our, or others’ rights, property, or safety; (iv) enforce Adinify Inc. policies or contracts; (v) collect amounts owed to Adinify Inc.; (vi) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation or prosecution of suspected or actual illegal activity; or (vii) if we, in good faith, believe that disclosure is otherwise necessary or advisable.`,
            `Additionally, from time to time, server logs may be reviewed for security purposes—e.g., to detect unauthorized activity on the Services. In such instances, server log data containing IP addresses may be shared with law enforcement bodies to identify users in connection with their investigation of the unauthorized activities.`,
            `Merger, Sale, or Other Asset Transfers: In the event that Adinify Inc. is involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, sale of company assets, or transition of service to another provider, your information may be sold or transferred as part of such a transaction as permitted by law and/or contract. Should such an event transpire, Adinify Inc. will endeavor to direct the transferee to use Personal Information in a manner consistent with the Policy in effect at the time such Personal Information was collected.`,
            `(b) Data Transfers`,
            `All Personal Information collected via or by Adinify Inc. may be stored in any location worldwide, including but not limited to, the United States, in the cloud, on our servers, on the servers of our affiliates, or the servers of our service providers. Your Personal Information may be accessible to law enforcement or other authorities pursuant to a lawful request. By providing information to Adinify Inc., you consent to the storage of your Personal Information in these locations.`
        ]
    },
    {
        title: `6.	Choice/Modalities to Opt-Out`,
        texts: [
            `(a) General`,
            `You possess the right to opt out of specific uses and disclosures of your Personal Information. In instances where you have provided consent to Adinify Inc. for the Processing of your Personal Information or sensitive Personal Information, you retain the ability to withdraw such consent at any time and opt-out of further Processing by adhering to the instructions delineated in this Section. Notwithstanding your opt-out, we may continue to collect and utilize non-Personal Information pertaining to your activities on our Website and/or information derived from advertisements on Third-Party websites for purposes unrelated to interest-based advertising, such as evaluating the effectiveness of advertisements.`,
            `(b) Email and Telephone Communications`,
            `Should you receive an unsolicited email from us, you may utilize the unsubscribe link located at the bottom of the email to opt-out of receiving future emails. We shall process your request within a reasonable timeframe following receipt. Please be advised that you will continue to receive transaction-related emails concerning products or services you have requested. Additionally, we may transmit certain communications regarding Adinify Inc. and our Services, from which you will not be able to opt out (e.g., communications concerning updates to our Terms or this Policy).`,
            `We maintain telephone “do-not-call” and “do-not-mail” lists as mandated by law. Requests to be placed on do-not-mail, do-not-phone, and do-not-contact lists will be processed within 60 days of receipt, or within such shorter time as may be required by law.`,
            `(c) Human Resources Data`,
            `With respect to Personal Information that Adinify Inc. receives in connection with the employment relationship, such Personal Information will be utilized solely for employment-related purposes as more fully described herein. Should Adinify Inc. intend to use this Personal Information for any other purpose, we will notify the Individual and provide an opportunity to opt-out of such uses.`,
            `(d) Do Not Track`,
            `Do Not Track (“DNT”) is a privacy preference that users can configure in certain web browsers. DNT serves as a mechanism for users to inform websites and services that they do not wish for certain information regarding their webpage visits to be collected over time and across websites or online services. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.`,
            `(e) Cookies and Interest-Based Advertising`,
            `We anticipate that you will find the display of interest-based Ads to be of value. However, should you wish to opt-out of these advertising services, you may modify your preferences by visiting the “Settings” page of the Website and selecting the option to opt-out.`,
            `As previously noted, you may halt or restrict the placement of cookies on your Devices or remove them from your browser by adjusting your web browser preferences. Please be aware that cookie-based opt-outs are not effective on mobile applications. However, on many mobile Devices, application users may opt out of certain mobile ads via their Device settings or by downloading the AppChoices mobile application offered by the Digital Advertising Alliance.`,
            `The online advertising industry also provides websites from which you may opt-out of receiving targeted ads from our data partners and other advertising partners that participate in self-regulatory programs. You can access these, and also learn more about targeted advertising and consumer choice and privacy, at optout.networkadvertising.org, youronlinechoices.eu, and optout.aboutads.info. You may also choose to exclude yourself from Google Analytics by following this link: tools.google.com/dlpage/gaoptout.`,
            `To clarify, whether you are utilizing our opt-out found at adinify.com/opt-out and/or an online industry opt-out, these cookie-based opt-outs must be executed on each Device and browser that you wish to have opted-out. For instance, if you have opted-out on your computer browser, that opt-out will not be effective on your mobile Device. You must separately opt-out on each Device. Advertisements on Third-Party websites that contain the AdChoices link and that link to this Privacy Policy may have been directed to you based on anonymous, non-Personal Information collected by advertising partners over time and across websites. These advertisements provide a mechanism to opt-out of the advertising partners’ use of this information for interest-based advertising purposes.`,
        ]
    },
    {
        title: `7.	SECURITY OF YOUR INFORMATION`,
        texts: [
            `The security of your Personal Information is of paramount importance to us. We maintain appropriate safeguards and adhere to current security standards to protect your Personal Information from unauthorized access, disclosure, or misuse, such as employing SSL encryption. However, no system can be rendered completely secure, and we cannot ensure or warrant the security of any information you provide to us. While we undertake meaningful steps to secure your information, we do not guarantee, and you should not expect, that your Personal Information will invariably remain secure. We do not accept liability for unintentional disclosure.`,
            `By utilizing the Website or providing Personal Information to us, you consent to our electronic communication with you regarding security, privacy, and administrative issues related to your use of the Website. In the event of a security system breach, we may attempt to notify you electronically by posting a notice on the Website or sending an e-mail to you. You may have a legal right to receive this notice in writing.`,
        ]
    },
    {
        title: `8.	Rights of Access, Rectification, Erasure, and Restriction`,
        texts: [
            `You are entitled to review and amend certain Personal Information associated with your utilization of our Website or Services, such as your name and contact details. To access your information, please log into your account, where you will have the capability to modify your Personal Information.`,
            `You may inquire whether Adinify Inc. is Processing Personal Information pertaining to you, request access to such Personal Information, and seek correction, amendment, or deletion of your Personal Information where it is inaccurate. Where permissible under applicable law, you may send an email to client-support@adinify.com or utilize any of the methods outlined in this Policy to request access to, receive (port), restrict Processing, seek rectification, or request erasure of Personal Information held by Adinify Inc. Such requests will be processed in accordance with local laws.`,
            `While Adinify Inc. endeavors to provide Individuals with access to their Personal Information in good faith, there may be circumstances where access cannot be granted, including but not limited to: instances where the information is subject to legal privilege, would infringe upon the privacy or other legitimate rights of others, where the burden or expense of providing access would be disproportionate to the risks to the Individual’s privacy in the specific case, or where it is commercially proprietary. If Adinify Inc. determines that access should be restricted in any particular instance, we will furnish you with an explanation of the rationale behind that determination and a contact point for any further inquiries. To safeguard your privacy, Adinify Inc. will take commercially reasonable measures to verify your identity before granting access to or making any changes to your Personal Information.`,
        ]
    },
    {
        title: `9.	Retention`,
        texts: [
            `Adinify Inc. retains the Personal Information we receive as delineated in this Policy for the duration of your use of our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.`
        ]
    },
    {
        title: `10.	CHILDREN’S PRIVACY`,
        texts: [
            `The Website is restricted to individuals over the age of 18. We do not knowingly collect, maintain, or use Personal Information from children under 13 (and in certain jurisdictions under the age of 16) years of age, and no part of the Website is directed to children under the age of 13 (and in certain jurisdictions under the age of 16). If you become aware that your child has provided us with Personal Information without your consent, you may notify us at client-support@adinify.com. If we become aware that we have collected any Personal Information from children under 13 (and in certain jurisdictions under the age of 16), we will promptly take steps to delete such information and terminate the child’s account.`
        ]
    },
    {
        title: `11.	International Users`,
        texts: [
            `By utilizing the Website, you consent to the transfer of data to the United States. If you are visiting from the European Union or other regions with laws governing data collection and use, please be advised that you are consenting to the transfer of your information to the United States and processing globally. By providing your Personal Information, you consent to any transfer and processing in accordance with this Policy.`
        ]
    },
    {
        title: `12.	REDRESS/COMPLIANCE AND ACCOUNTABILITY`,
        texts: [
            `Questions or concerns regarding our compliance with this Policy may be directed to client-support@adinify.com or contact us at:`,
            `Adinify Inc. 7191 Yonge St Suite 812, Torono, ON, L3T0C4, Canada`,
            `Adinify Inc. will address your concerns and endeavor to resolve any privacy issues in a timely manner.`,
            `If you are an EU citizen and believe that Adinify Inc. is not adhering to the terms of this Privacy Policy, please contact us at the contact information provided above. If any request remains unresolved, you may contact the national data protection authority for your EU Member State.`,
        ]
    },
    {
        title: `13.	OTHER RIGHTS AND IMPORTANT INFORMATION`,
        texts: [
            `(a) CHANGES TO OUR PRIVACY POLICY AND PRACTICES`,
            `Revision to the Privacy Policy: We reserve the right to revise this Privacy Policy at our sole discretion, so please review it periodically. If you continue to visit this Website or use our Services after such changes have been made, you hereby provide your consent to the changes.`,
            `Posting of Revised Privacy Policy: If there are any material changes to this Policy, Adinify Inc. will notify you by email or as otherwise required by applicable law. We will post any adjustments to the Privacy Policy on this web page, and the revised version will be effective immediately when it is posted (or upon notice as applicable). If you are concerned about how your information is used, please bookmark this page and review this Privacy Policy periodically.`,
            `New Uses of Personal Information: Additionally, before we use Personal Information for any new purpose not originally authorized by you, we will endeavor to provide information regarding the new purpose and give you the opportunity to opt-out. Where consent of the Individual for the Processing of Personal Information is otherwise required by law or contract, Adinify Inc. will endeavor to comply with the law or contract.`,
        ]
    },
    {
        title: `CALIFORNIA PRIVACY RIGHTS`,
        texts: [
            `California law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the Third Parties to whom we have disclosed their Personal Information (if any) for their direct marketing purposes in the prior calendar year, as well as the type of Personal Information disclosed to those parties. Adinify Inc. does not share Personal Information with Third Parties for their own marketing purposes.`,
            `The last update to our Privacy Policy was posted on Jan 15, 2025.`
        ]
    }
]

export default partnersPrivacyData