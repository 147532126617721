const privacyPolicyData = [
    {
        title: 'CCPA Privacy Policy',
        texts: [
            `This Privacy Policy outlines the manner in which Adinify Inc. collects and processes personal information of users ("Users PI"). Adinify receives Users PI from publishers ("Supply Side Partners") and Supply Side Platform Partners ("SSP Partners"), and shares this Users PI with advertisers ("Demand Partners") to deliver various services, including personalized advertising and related adtech services ("Services"). Supply Partners and SSP Partners provide Users PI to Adinify to facilitate ad monetization Services by sharing such Users PI with Demand Partners, who in turn deliver personalized ads to Users.`,
        ]
    },
    {
        title: 'Information Collection and Purpose',
        texts: [
            `Adinify does not engage directly with Users. Supply Partners integrate a Software Development Kit ("SDK") on their mobile sites. The SDK enables the transfer of Users PI to Adinify, allowing Adinify to provide ad monetization Services to the Supply Partner, including the placement and serving of personalized ads on mobile sites for User viewing. For instance, if a User accesses a mobile application where the Supply Partner has embedded Adinify’s SDK, the SDK will transmit to Adinify only the Users PI that the Supply Partner has authorized Adinify to receive for the business purposes of delivering the Services.`,
            `SSP Partners provide Adinify with Users PI for the business purposes of delivering the Services. Supply Partners and SSP Partners bear the responsibility of ensuring that Users PI is collected in compliance with applicable laws. Adinify mandates that its Supply Partners, SSP Partners, and Demand Partners furnish a privacy notice as required by applicable laws.`,
            `Adinify may receive the following Users PI:`,
            `•	Information about User’s mobile advertising identifiers, such as User’s Advertising ID (Apple IDFA or Google AAID).`,
            `•	Information provided by User’s device, such as device IP address, network connection type, and device GPS location.`,
            `•	Information regarding User’s age and gender.`,
            `Adinify processes this Users PI solely for the business purposes of delivering the Services, which include:`,
            `•	Providing personalized ads to Users;`,
            `•	Supporting SSP Partners;`,
            `•	Analyzing the performance of the Services;`,
            `•	Enhancing the Services; `,
            `•	Safeguarding the Services from fraud, misuse, and illegal activity.`
        ]
    },
    {
        title: 'Data Sharing',
        texts: [
            `At the direction of its SSP Partners, Adinify shares Users PI with its Demand Partners for the purpose of delivering Services, including serving personalized ads to Users.`,
            `In addition to Demand Partners, Adinify shares Users PI with other trusted Service Providers who offer services for business purposes, such as hosting services, security services, and marketing services.`,
        ]
    },
    {
        title: 'Aggregated Information',
        texts: [
            `Adinify may utilize aggregated information (excluding personal information) to analyze trends and improve our Services.`,
        ]
    },
    {
        title: 'Legal Compliance',
        texts: [
            `Adinify may disclose Users PI to a third party if (a) it deems such disclosure reasonably necessary to comply with any applicable law, regulation, legal process, or governmental request, or (b) to protect the security or integrity of the Services from harm or illegal activities.`,
        ]
    },
    {
        title: 'Business Transfers',
        texts: [
            `Adinify may share or transfer Users PI in connection with any merger, sale of Adinify assets, financing, or acquisition of all or a portion of the business by another company.`
        ]
    },
    {
        title: 'California Users',
        texts: [
            `Adinify operates as a Service Provider under the California Consumer Protection Act ("CCPA"). Adinify adheres to the instructions of SSP Partner(s) in processing Users PI for the business purposes of delivering the Services. Adinify follows SSP Partner(s)’ instructions to delete any requested Users PI. Adinify does not sell Users PI as defined under CCPA and upholds Users' right to non-discrimination as defined under CCPA.`,
        ]
    },
    {
        title: 'Nevada Users',
        texts: [
            `Adinify does not sell Users PI as defined by Section 1.6 of Chapter 603A of the Nevada Revised Statutes.`
        ]
    },
    {
        title: 'Data Retention',
        texts: [
            `Adinify retains Users PI as long as necessary to provide the Services unless a longer retention period is required or permitted for legal or compliance purposes.`
        ]
    },
    {
        title: 'Do Not Track',
        texts: [
            `Some browsers offer a "do not track" ("DNT") option. Adinify does not respond to DNT signals.`
        ]
    },
    {
        title: 'Security',
        texts: [
            `Adinify employs industry-standard technological measures reasonably designed to protect information from loss, unauthorized access, disclosure, alteration, or destruction. While Adinify takes reasonable efforts to safeguard information, no security system is impenetrable.`
        ]
    },
    {
        title: 'Policy on Information Collected from Children',
        texts: [
            `This Site and our Services are not directed at children under the age of 13. Adinify does not knowingly collect Users PI from children under the age of 13.`
        ]
    },
    {
        title: 'Policy Changes',
        texts: [
            `Adinify may update this Privacy Policy periodically to reflect changes to our Services and applicable laws. If changes are made, Adinify will revise the "Last Updated" date at the top of this Privacy Policy on the Site. Continued use of this Site or the Services after such notice will be subject to the terms of the then-current Privacy Policy.`
        ]
    },
    {
        title: 'Contact Information',
        texts: [
            `For any questions regarding this Privacy Policy, please contact us via email at client-support@adinify.com.`,
            'Last Updated & Effective Date: January 15, 2025'
        ]
    }
]

export default privacyPolicyData