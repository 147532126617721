import Grid from '@mui/material/Grid2'
import { Container } from '@mui/material';
import React from 'react';

export const WideContainer =({className='', children})=> {
    return (
        <Container disableGutters maxWidth='false' className={className}>
            {children}
        </Container>
    );
}
export const NarrowContainer =({className='', vertical=false, children})=> {
    return (
        <Grid container maxWidth='lg' display='flex' flexDirection={vertical? 'column':'row'} className={className} style={{zIndex:2}}>
            {children}
        </Grid>
    );
}
