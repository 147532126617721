import React from 'react';
import Grid from '@mui/material/Grid2';
import { Outlet } from "react-router-dom";
import './layout.css'
import NavigationBar from 'components/Base/Navi/components/Navbar';
import Footer from 'components/Base/Footer/footer';

const Layout=()=> {
    return (
        <Grid container width='100%' display='flex' flexDirection='column' justifyContent='center'>
            <NavigationBar/>
            <Outlet/>
            <Footer/>
        </Grid>
    );
}

export default Layout;