import { React,useState,useEffect} from 'react';
import axios from 'axios.js';
import { FormControl, Select, InputLabel, MenuItem, TextField, Button, Snackbar, Alert,Backdrop,CircularProgress,Checkbox,FormControlLabel} from '@mui/material';
import { useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import CountryList from 'components/Base/countries.json'
import Grid from '@mui/material/Grid2'

const initialFormValues = {
    email_type: 'adinifyContact',
    email: '',
    publisherName:'',
    domain:'',
    firstName: '',
    lastName:'',
    link:'',
    companyType:'',
    country:'',
    concent:false,
  };

const initialAlertValues ={
    alertOpen: false,
    alertType: "success",
    alertMessage: ""
}

const companyType = [
    {value:'gam',name:'AdMob/GAM'},
    {value:'applovin',name:'Applovin Max'},
    {value:'ironsource',name:'ironSource'},
    {value:'chartboost',name:'Chartboost'},
    {value:'appodeal',name:'Appodeal'},
    {value:'mediation',name:'Self-Mediation'},
    {value:'other',name:'Other'},
    {value:'none',name:'I don‘t have mediation'},
]

const SignUpForm =()=> {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [formValues, setFormValues] = useState(initialFormValues);
    const [alertValues, setAlertValues ] = useState(initialAlertValues);
    const [errors, setErrors] = useState({});
    const [onchangeItem,setOnChangeItem]=useState({});
    const [loading, setLoading] = useState(false);
    // const [loadingTimeOut,setLoadingTimeOut] = useState(false);

    const receiveConcent = (
        <>
            I agree to receive communications from Adinify‘s company. Adinify protects and respects your privacy. 
            We will only use your personal information to administer your account and provide the products and services 
            you requested. You may unsubscribe from these communications at any time. For more information, 
            please review our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
        </>
    );

    const storeConcent = 'I consent to Adinify to store and process the personal information submitted above.'

    useEffect(()=>{
        const validate = (fieldValues = formValues) => {
            let temp = {...errors}
            if ('email' in fieldValues){
                if(formValues.email.length===0){
                    temp.email=''
                }else if(formValues.email.length<5 || formValues.email.length>250){
                    temp.email="The email must be more than 5 and less than 250 characters"
                }else if(!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)){
                    temp.email="Email is not valid.";
                }
                else temp.email='';
            }
            if ('domain' in fieldValues) {
                if(formValues.domain.length===0){
                    temp.domain = ''
                }else
                temp.domain=(formValues.domain.length > 3 && formValues.domain.length <= 250)? '':"The domain must be more than 3 and less than 250 characters";
            }
            if ('publisherName' in fieldValues){
                if(formValues.publisherName.length>250){
                    temp.publisherName="The publisher name must be less than 250 characters"
                }else if(!/^[a-zA-Z\s]*$/.test(fieldValues.name)){
                    temp.publisherName="The publisher name must be only letters.";
                }
                else temp.publisherName='';
            }
            if ('firstName' in fieldValues){
                if(formValues.firstName.length>250){
                    temp.firstName="The first name must be less than 250 characters"
                }else if(!/^[a-zA-Z\s]*$/.test(fieldValues.name)){
                    temp.firstName="The first name must be only letters.";
                }
                else temp.firstName='';
            }
            if ('lastName' in fieldValues){
                if(formValues.lastName.length>250){
                    temp.lastName="The last name must be less than 250 characters"
                }else if(!/^[a-zA-Z\s]*$/.test(fieldValues.name)){
                    temp.lastName="The last name must be only letters.";
                }
                else temp.lastName='';
            }
            if ('link' in fieldValues) {
                if(formValues.link.length === 0){
                    temp.link = ''
                }else{
                const isValidLink = /^(https?:\/\/)/.test(formValues.link);
                temp.link=(formValues.link.length > 3 && formValues.link.length <= 250 && isValidLink)? '':"The link must be more than 3 and less than 250 characters and start with 'http://' or 'https://'";
            }
            }
            setErrors({
                ...temp
            });
        }
        validate(onchangeItem)
    },[onchangeItem, formValues, errors])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertValues(initialAlertValues)
    };

    const formIsValid = (fieldValues = formValues) => {
        const isValid =
          fieldValues.email &&
          fieldValues.domain &&
          fieldValues.publisherName &&
          fieldValues.firstName &&
          fieldValues.lastName &&
          fieldValues.link &&
          fieldValues.companyType &&
          fieldValues.country &&
          Object.values(errors).every((x) => x === "");
        return isValid;
      };

    // const sendEmailAsync = async () => {
    //     if (executeRecaptcha) {
    //         const token = await executeRecaptcha('submit_sign_up_form');
    //         const updatedFormValue = {...formValues,"g-recaptcha-response":token}
    //         const status = await axios.post("/send-email",updatedFormValue)
    //         .then((res) => {
    //             return (res.data && res.data.status != null) ? res.data : {status: false}
    //         })
    //         .catch((error)=> {
    //             return {status: false}
    //         });
    //         return status;
    //     }else{
    //         setLoading(true)
    //         console.error("Execute recaptcha function not yet available");
    //     }
    // }
    
    useEffect(()=>{
        if(executeRecaptcha){
            setLoading(false)
        }else{
            console.log('finished')
        }
    },[executeRecaptcha])

    // useEffect(()=>{
    //     if(loading){
    //         setTimeout(() => {
    //             setLoadingTimeOut(true)
    //         }, 30000);
    //     }else{
    //         setLoadingTimeOut(false)
    //     }
    // },[loading])

    // useEffect(()=>{
    //     if(loadingTimeOut && loading){
    //         setAlertValues({
    //             alertOpen: true,
    //             alertType: "warning",
    //             alertMessage: "Request expired. Please refresh the page."
    //         })
    //     }
    // },[loadingTimeOut,loading])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formIsValid()){
            // let info = await sendEmailAsync();
            // console.log(info)
            // if(info){
            //     setLoading(false)
            // setAlertValues({
            //     alertOpen: true,
            //     alertType: (info.status) ? "success" : "warning",
            //     alertMessage: info.msg || "Something went wrong."
            // })
            // event.target.reset();
            // setFormValues(initialFormValues)
            // }
            try {
                // Post the form data using axios
                const response = await axios.post('/send-email/', formValues);
          
                if (response.status === 200) {
                  console.log("Form submitted successfully:", response.data);
                  setFormValues(initialFormValues);
                } else {
                    console.error("Error submitting form:", response.data);
                    setErrors({ form: "Failed to submit the form. Please try again." });
                  }
                } 
            catch (error) {
                  console.error("Error during form submission:", error);
                  setErrors({ form: "An error occurred during submission. Please try again." });
                }
        }else{
            console.log('errors',errors)
        }

    };


    const handleFormChange = (e) => {
        const { name, type, checked, value } = e.target;
        const fieldValue = type === "checkbox" ? checked : value;

        setOnChangeItem({ [name]: fieldValue })
        setFormValues({
            ...formValues,
            [name]: fieldValue,
        });
    };

    return (
            <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '900px' }}>
            <Grid container sx={{p:2}} alignItems="center" justify="center">
                <Grid size={{xs:12}} className='center-align'>
                <TextField id="email-input"
                    name="email"
                    label="Email"
                    type="text"
                    sx={{ m: 1, width: {md:'95%',xs:'90%'} }}
                    value={formValues.email}
                    onChange={handleFormChange}
                    required
                    helperText=" "
                    {...(errors["email"] && { error: true, helperText: errors["email"] })}/>
                </Grid>
                <Grid size={{md:6,xs:12}} className='center-align'>
                <TextField id="publisher-name-input"
                    name="publisherName"
                    label="Publisher Name"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.publisherName}
                    onChange={handleFormChange}
                    required
                    helperText=" "
                    {...(errors["publisherName"] && { error: true, helperText: errors["publisherName"] })}/>
                </Grid>
                <Grid size={{md:6,xs:12}} className='center-align'>
                <TextField id="domain-input"
                    name="domain"
                    label="Company Domain"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.domain}
                    onChange={handleFormChange}
                    required
                    helperText=" "
                    {...(errors["domain"] && { error: true, helperText: errors["domain"] })}/>
                </Grid>
                <Grid size={{md:6,xs:12}} className='center-align'>
                <TextField id="firstName-input"
                    name="firstName"
                    label="First Name"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.firstName}
                    onChange={handleFormChange}
                    required
                    helperText=" "
                    {...(errors["firstName"] && { error: true, helperText: errors["firstName"] })}/>
                </Grid>
                <Grid size={{md:6,xs:12}} className='center-align'>
                <TextField id="lastName-input"
                    name="lastName"
                    label="Last Name"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.lastName}
                    onChange={handleFormChange}
                    required
                    helperText=" "
                    {...(errors["lastName"] && { error: true, helperText: errors["lastName"] })}/>
                </Grid>
                <Grid size={{xs:12}} className='center-align'>
                <TextField id="link-input"
                    name="link"
                    label="Google Play or Apple Appstore Link to Top DAU Bundle"
                    type="text"
                    sx={{ m: 1, width: {md:'95%',xs:'90%'} }}
                    value={formValues.link}
                    onChange={handleFormChange}
                    required
                    helperText=" "
                    {...(errors["link"] && { error: true, helperText: errors["link"] })}/>
                </Grid>
                <Grid size={{md:6,xs:12}} mb={4} className='center-align'>
                    <FormControl fullWidth sx={{width: '90%' }}>
                        <InputLabel id="company-type-label">Mediation Type *</InputLabel>
                        <Select
                            labelId="company-type-label"
                            id="company-type-select"
                            value={formValues.companyType}
                            name="companyType"
                            label="Mediation Type"
                            onChange={handleFormChange}
                            required
                            {...(errors["companyType"] && { error: true, helperText: errors["companyType"] })}
                        >
                            {companyType && companyType.map((type, i) =>
                                <MenuItem key={i} value={type.value}>{type.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid size={{md:6,xs:12}} mb={4} className='center-align'>
                    <FormControl sx={{ width: '90%' }}>
                        <InputLabel id="country">Country *</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={formValues.country}
                            name="country"
                            label="Country"
                            onChange={handleFormChange}
                            required
                            {...(errors["country"] && { error: true, helperText: errors["country"] })}
                        >
                            {CountryList && CountryList.map((country, i) =>
                                <MenuItem key={i} value={country.Code}>{country.Name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container direction="column" alignItems="flex-start" mb={5} ml={4}>
                    <Grid item>
                        <FormControlLabel
                        name="checkbox1"
                        required
                        control={<Checkbox />}
                        label={receiveConcent}
                        style={{ marginBottom: '8px' }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                        checked={formValues.concent}
                        onChange={handleFormChange}
                        name="concent"
                        required
                        control={<Checkbox />}
                        label={storeConcent}
                        style={{ marginBottom: '8px' }}
                        />
                    </Grid>
                </Grid>
                <Grid size={{xs:12}}  className='center-align'>
                <Button sx={{ m: 1, width: {md:'95%',xs:'90%'} }} variant="contained" type="submit">
                    Submit
                </Button>
                </Grid>
                <Snackbar open={alertValues.alertOpen} autoHideDuration={20000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertValues.alertType} sx={{ width: '100%' }}>
                    {alertValues.alertMessage}
                </Alert>
                </Snackbar>
            </Grid>
            <Backdrop sx={{ color: '#fff', zIndex: '99'}} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </form>
    );
}

export default SignUpForm;