import React from 'react';
import Grid from '@mui/material/Grid2'
import { IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Grid
      container
      sx={{
        backgroundColor: 'rgb(25, 118, 210)',
        minHeight: '120px',
        padding: '20px',
      }}
      width="100%"
      marginTop="60px"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ maxWidth: '1200px', width: '100%' }}
      >
        <Grid container direction='column' spacing={2} sx={ { marginBottom: { xs: '15px', md: '0' } } }>
          <Grid>
            <Grid container alignItems='center' spacing={2}>
              <p style={{ color: 'white', fontWeight: 'bold', fontSize: '18px' }}>
                &copy; {year} Adinify.
              </p>
              <Grid container spacing={2} sx={ { fontSize: { xs: '10px', md: '16px' }, color: 'white' } }>
                <a href="/partners-privacy" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                  Partners Privacy
                </a>
                <a href="/opt-out" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                  Opt Out
                </a>
                <a href="/terms-of-service" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                  Terms Of Service
                </a>
                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                  Privacy Policy
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={ { fontSize: { xs: '10px', md: '14px' }, color: 'white' } }>
            <p style={{ fontSize: 'inherit' }} >
              Adinify Inc. 7191 Yonge St Suite 812, Toronto, ON, L3T0C4, Canada
            </p>
          </Grid>
        </Grid>
      
        <Grid
          container
        >
          <a href="https://www.linkedin.com/company/adinifyads/?viewAsMember=true" target="_blank" rel="noopener noreferrer"> <IconButton> <LinkedInIcon sx={{ color: 'white' }} fontSize="12px" /></IconButton></a>
        </Grid>
      </Grid>
    </Grid>
  );
};


export default Footer;